import React, { useEffect, useState } from "react";
import { IUserManagement, UserInterface } from "../../../types/userTypes";
import InfoBox from "../../common/boxes/InfoBox/InfoBox";
import ContentContainer from "../../layout/ContentContainer";
import DropdownPanel from "../../common/panels/DropdownPanel";
import { useParams } from "react-router";
import InfoBoxPlaceholder from "../../common/boxes/InfoBox/InfoBoxPlaceholder";

import Placeholder from "../../../assets/images/placeholder.svg";
import UserDetailTopbar from "./UserDetailTopbar";
import EditPermissions from "../permissions/EditPermissions";
import { showServerErrors } from "utils/errorsUtils";
import EditNotifications from "../permissions/EditNotifications";
import jwtDecode, { JwtDecodeOptions } from "jwt-decode";
import { getUserDetail } from "services/usersServices";

const UserDetail: React.FC = () => {
  const [user, setUser] = useState<any | null>(null);
  const { id } = useParams<{ id: string }>();
  const token = localStorage.getItem("token");
  const decodedToken: any = token && jwtDecode(token);
  // console.log("TEST");
  useEffect(() => {
    getUserDetail(id)
      .then((data) => {
        setUser(data.Data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);
  if (!user) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <ContentContainer
      title={`${user.FirstName} ${user.LastName}`}
      TopBar={<UserDetailTopbar user={user} IsActivate={user.IsActivate} />}
      // TopBar={<></>}
      path="/users"
    >
      <div className="flex flex-col">
        <InfoBox className="bg-white-dirty p-18">
          <InfoBox.Image src={Placeholder} />

          <InfoBox.Items>
            <InfoBox.InfoItem
              label={"Imię i nazwisko"}
              value={`${user.FirstName} ${user.LastName}`}
            />
            <InfoBox.InfoItem
              label={"Numer telefonu"}
              value={
                user?.Contact?.PhoneNumber ? user.Contact.PhoneNumber : "-"
              }
            />
            <InfoBox.InfoItem label={"Stanowisko"} value={user.Position} />
            <InfoBox.InfoItem
              label={"Nick"}
              value={user?.Nickname ? user.Nickname : "-"}
            />
            <InfoBox.InfoItem
              label={"Email"}
              value={user?.Contact?.Email ? user.Contact.Email : "-"}
            />
            <InfoBox.InfoItem
              label={"Status"}
              value={user.IsActivate ? "Aktywny" : "Nieaktywny"}
            />
            <InfoBox.InfoItem /> <InfoBox.InfoItem /> <InfoBox.InfoItem />
          </InfoBox.Items>
        </InfoBox>
        {decodedToken.Role === '0' && <EditPermissions user={user} />}

        {/*  {((id === user.UserId && user.Position === 'Admin')||
          decodedToken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ] === "2") && <EditNotifications user={user} />}

        {decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ] === "2" && (
          <DropdownPanel
            label="Uprawnienia"
            // initialExpanded
          >
            <EditPermissions user={user} />
          </DropdownPanel>
        )} */}
      </div>
    </ContentContainer>
  );
};

export default UserDetail;
