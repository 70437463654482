import React, { useState, useContext, useEffect } from 'react';

import {
    IListPageRequest,
    IListPageResponse,
    ISelectOption,
    LIST_SORT,
} from 'types/globalTypes';

import {
    IListedScanner,
    IScanner,
    IScannerPlayer,
} from 'types/scanner/scanner.interface';
import { enumToSelectOptions } from 'utils/baseUtils';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { getScanner } from 'middleware/scanner/getFilteredPlayers';
import ContentContainer from 'components/layout/ContentContainer';
import ScannerTopbarTrainer from '../../trainers/outside/ScannerTopBarTrainer';
import { useDebounce } from 'use-debounce';
import { DefaultSortContext } from 'contexts/defaultSortContext';
import { LIST_VIEW } from 'types/transfer/trainer.interface';
import scannerServices from 'services/scanner/scannerServices';
import RequestedTrainersTable from 'components/trainers/outside/RequestedTrainersTable'
import ScannerTrainersTable from 'components/trainers/outside/ScannerTrainersTable';
import { IFilterPlayerValue } from 'types/filterPassData';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const sorts = enumToSelectOptions(LIST_SORT);
const listViews = enumToSelectOptions(LIST_VIEW);

const selectOptionFilters = (filters: IListedScanner[]) =>
    filters.map(filter => ({
        label: `${filter.Name} ( ${filter.IsActivate ? 'Aktywny' : 'Nieaktywny'
            } )`,
        value: filter.FilterId,
    }));

const TrainerOutside = () => {

    const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

    const [sortBy, setSortBy] = useState<ISelectOption<LIST_SORT> | null>(null);

    const [queryString, setQueryString] = useState('');

    const [listView, setListView] =
        useState<ISelectOption<LIST_VIEW> | null>(null);

    const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

    const [scanner, setScanner] = useState<IScanner>();

    const [filters, setFilters] = useState<ISelectOption<string>[]>([]);

    const [filter, setFilter] = useState<ISelectOption<string> | null>(null);

    const [isQueryEmpty, setIsQueryEmpty] = useState(false);

    const [appliedFilter, setAppliedFilter] = useState<IFilterPlayerValue | undefined>(undefined);

    const permission = useSelector((state: any) => state.permissions.permissions);

    const isAdmin = permission[0]?.Id === -1;

    const getScannerPlayers = async (
        pageInfo: IListPageRequest
    ): Promise<IListPageResponse<IScannerPlayer>> => {
        if (!filter) {
            return {
                Items: [],
                HasNextPage: false,
                HasPreviousPage: false,
                pageNumber: 0,
            };
        }

        const scannerResponse = await getScanner(filter.value, pageInfo);
        setScanner(scannerResponse);

        return scannerResponse.Players;
    };

    const getFilters = async () => {
        const response = await scannerServices.getListing();
        setFilters(selectOptionFilters(response.Items));
    };

    const {
        items: scannerPlayers,
        loading,
        containerRef,
        lastItemRef,
        refresh: refreshScanner,
    } = useInfiniteScroll<IScannerPlayer>(getScannerPlayers, debounceSearchInput);

    const handleQueryChange = (query: string) => {
        setQueryString(query);
    };

    const viewLabels = [
        { label: 'Dostępni trenerzy', value: 0 },
        { label: 'Zaproszenia', value: 1 },
    ];

    const handleFilterData = (obj: IFilterPlayerValue | undefined) => {
        setAppliedFilter(obj);
    }

    const isFilterTrainerActive = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 159);

    const isTrainerDetailActive = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 137);

    return (
        <ContentContainer
            title={'Radar trenerów'}
            TopBar={
                <ScannerTopbarTrainer
                    defaultSort={defaultSort}
                    handleQueryChange={handleQueryChange}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sorts={sorts}
                    listView={listView}
                    setListView={setListView}
                    listViews={listViews}
                    refreshScanner={refreshScanner}
                    refreshFilters={getFilters}
                    filter={filter}
                    setFilter={setFilter}
                    filters={filters}
                    isQueryEmpty={isQueryEmpty}
                    viewLabels={viewLabels}
                    handleFilterData={handleFilterData}
                    isFilterTrainerActive={isFilterTrainerActive}
                />
            }
        >
            {(listView?.value === LIST_VIEW.Zaproszenia
                || listView === null) ? (
                <div
                    onClick={() => {
                        if (!isTrainerDetailActive) {
                            toast.error("Nie masz uprawnień. Skontaktuj sie z Administratorem")
                        }
                    }}
                >
                    <div
                        style={{
                            pointerEvents: isTrainerDetailActive ? "auto" : "none"
                        }}
                    >
                        <ScannerTrainersTable
                            sortBy={sortBy}
                            queryString={debounceSearchInput}
                            appliedFilter={appliedFilter}
                        />
                    </div>
                </div>
            ) : (
                <div
                    onClick={() => {
                        if (!isTrainerDetailActive) {
                            toast.error("Nie masz uprawnień. Skontaktuj sie z Administratorem")
                        }
                    }}
                >
                    <div
                        style={{
                            pointerEvents: isTrainerDetailActive ? "auto" : "none"
                        }}
                    >
                        <RequestedTrainersTable
                            queryString={debounceSearchInput}
                            sortBy={sortBy}
                        />
                    </div>
                </div>
            )}
        </ContentContainer>
    )
}

export default TrainerOutside