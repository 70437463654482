import DropdownPanel from "../../common/panels/DropdownPanel";

interface IPermissionSectionProps {
  label: string;
  initialExpanded?: boolean;
}

const PermissionSection: React.FC<IPermissionSectionProps> = ({
  label,
  children,
  initialExpanded
}) => {
  return (
    <DropdownPanel label={label} initialExpanded>
      <div
        // className="grid gap-x-16 gap-y-4 py-12 px-24 md:px-36 text-sm"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
          width: "100%",
          paddingBottom: '20px'
        }}
      >
        {children}
      </div>
    </DropdownPanel>
  );
};

export default PermissionSection;
