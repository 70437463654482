import cs from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { INavbarItem } from "./navbarTypes";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const grouplinksPermissionsHelper = [
  { label: 'Zamówienia', Id: 160, disabeld: false },
  { label: 'Składki', Id: 161, disabled: false },
  { label: 'Produkty', Id: 162, disabled: false },
  { label: 'Kategorie', Id: 163, disabled: false },
]

const NavbarItem: React.FC<INavbarItem> = ({
  icon: Icon,
  label,
  items,
  path,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const subpageName = location.pathname.split("/")[1];

  const handleExpand = () => setIsExpanded(!isExpanded);

  const listClasses = cs("overflow-hidden", {
    "h-0": !isExpanded,
    "-mt-1 mb-2 ml-2": items?.length && isExpanded,
  });

  const permission = useSelector((state: any) => state.permissions.permissions);

  const navItemsWithPermissions = items?.map((item: any) => {
    const matchedPermission = grouplinksPermissionsHelper.find(
      (helperItem) => helperItem.label === item.label
    );
    return {
      ...item,
      disabled: matchedPermission
        ? !permission.some((perm: any) => perm.Id === matchedPermission.Id)
        : true,
    };
  });

  const navbarShopTabs = permission[0]?.Id === -1 ? items : navItemsWithPermissions

  return (
    <div>
      {path ? (
        <NavLink
          activeClassName="text-blue"
          className="navItem"
          to={path}
          exact={path === "/"}
          onClick={handleExpand}
          style={{
            color: "#909090",
            letterSpacing: "-0.5px",
            padding: "6px 18px",
          }}
          activeStyle={{ color: "#fff" }}
        >
          <Icon className="fill-current" />
          <span>{label}</span>
        </NavLink>
      ) : (
        <div
          className="navItem"
          style={{
            color: subpageName === "shop" ? "#fff" : "#909090",
            letterSpacing: "-0.5px",
          }}
          onClick={handleExpand}
        >
          <Icon />
          <span>{label}</span>
        </div>
      )}

      <div
        className={listClasses}
        style={{ overflow: "auto", maxHeight: "100px" }}
      >
        {navbarShopTabs?.map(({ disabled, label, path, icon: ItemIcon }) => (
          <div
            onClick={() => {
              if (disabled) {
                toast.error("Nie masz uprawnień. Skontaktuj się z Administratorem")
              }
            }}
          >
            <div
              style={{
                pointerEvents: !disabled ? 'auto' : 'none',
                opacity: disabled ? '0.4' : '',
              }}
            >
              <NavLink
                activeClassName="text-blue"
                exact={path === "/home"}
                style={{
                  color: "#909090", letterSpacing: "-0.5px",
                }}
                activeStyle={{ color: "#fff" }}
                key={label}
                to={path}
                className="navItem text-xs py-4"
              >
                <div>
                  {ItemIcon && <ItemIcon className="flex justify-center" />}
                </div>
                <div >
                </div>
                <span>{label}</span>
              </NavLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavbarItem;
