import React, { createContext, useEffect, useState, useRef } from "react";
import chatServices from "services/chatServices";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnection,
} from "@microsoft/signalr";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

interface Props {
  children: React.ReactNode;
}

export const AllConversationsContext = createContext<any>(null!);

const AllConversationsProvider: React.FC<Props> = ({ children }) => {
  const [activeConversationId, setActiveConversationId] = useState("");
  const [tempMemberId, setTempMemberId] = useState("");
  const [activeConversationType, setActiveConversationType] =
    useState<any>(null);
  const [auxFolderName, setAuxFolderName] = useState("");
  const [allConversations, setAllConversations] = useState<any>([]);
  const [conversationMembers, setConversationMembers] = useState<any>([]);
  const [unreadMessagesCounter, setUnreadMessagesCounter] = useState<any>(0);
  const [allInterlocutors, setAllInterlocutors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("");
  const connection = useRef<any>(null);
  const [conversation, setConversation] = useState<any>([]);
  const [activeConversationImageUrl, setActiveConversationImageUrl] =
    useState("");
  const [activeConversationName, setActiveConversationName] = useState("");
  const [activeMemberType, setActiveMemberType] = useState(1);
  const [onlineProfiles, setOnlineProfiles] = useState<any>([]);
  useEffect(() => {
    console.log("Wszystkie wiadomosci", conversation);
  }, [conversation]);

  // useEffect(() => {
  //   const handleTabClose = (event: any) => {
  //     connection.current.stop();
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);
  //   document.addEventListener("beforeunload", handleTabClose);
  //   window.addEventListener("unload", handleTabClose);
  //   document.addEventListener("unload", handleTabClose);
  // }, []);

  const token = localStorage.getItem("token");
  const decodedToken: any = token && jwtDecode(token);

  const headers = {
    ProfileId: decodedToken?.AcademyId,
    ProfileType: "3",
    UserId: "",
    "X-API-KEY-X": "2ad239afd7851400f403172ddea8837fe3ec7807",
    // Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("XDDD");
      const newConnection = new HubConnectionBuilder()
        .withUrl("https://justwin.pl/Chat/chathub", {
          transport: HttpTransportType.LongPolling,
          headers: headers,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Error)
        .build();
      connection.current = newConnection;
    }

    return () => {
      if (connection.current) {
        connection.current
          .stop()
          .then(() => console.log("SignalR connection closed."))
          .catch((error: any) =>
            console.log("Error while closing SignalR connection:", error),
          );
      }
    };
  }, []);

  const getUnreadMessages = async () => {
    const resp: any = await chatServices.getAllUnreadMessages();
    console.log("unreadmessages>>>>>>>>>>>>>>>>>>", resp);
    setUnreadMessagesCounter(resp);
  };

  useEffect(() => {
    getUnreadMessages();
  }, []);

  const getAllConversations = async () => {
    const resp: any = await chatServices.getAllConversations(
      searchQuery,
      // filter,
    );
    console.log("respASTAT>>", resp);
    setAllConversations(resp.Items);
  };

  useEffect(() => {
    getAllConversations();
  }, [searchQuery, filter]);

  useEffect(() => {
    if (connection.current && allConversations.length > 0) {
      connection.current
        .start()
        .then((result: any) => {
          if (!connection.current) return console.log("nie podlaczono");
          console.log("Connected!");
          console.log("allconv1", allConversations);

          const editMessage = (id: string) => {
            setConversation((prevConversation: any) =>
              prevConversation.map((msg: any) => {
                if (msg.Id === id) {
                  return {
                    ...msg,
                    Content: "Wiadomość usunięta",
                    IsDelete: true,
                  };
                } else {
                  return msg;
                }
              }),
            );
          };

          const editConversationName = (id: string, Name: string) => {
            setAllConversations((prevConversations: any) =>
              prevConversations.map((conversation: any) => {
                if (conversation.Id === id) {
                  return {
                    ...conversation,
                    Name,
                  };
                } else {
                  return conversation;
                }
              }),
            );
          };

          connection.current.on("SendMessage", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("SendMessage:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            // toast.info("Nowa wiadomość!");

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 0,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            if (Conversation.Id === activeConversationId) {
              chatServices.readMessageById(
                Conversation.Id,
                Conversation.LastMessage.Id,
              );
            }
            if (decodedToken?.AcademyId !== Profile.Id) {
              setUnreadMessagesCounter((prev: any) => prev + 1);
            }

            console.log("CONVERSATIONID>>>>>>>>>>", Conversation.Id);
            console.log("activeConversationId", activeConversationId);

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });
          });
          connection.current.on("ReadMessage", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("ReadMessage:", parsedMessage);
            const { ConversationId, Profile, ReadMessage } = parsedMessage;
            // toast.info("Nowa wiadomość!");

            if (ConversationId === activeConversationId) {
              setUnreadMessagesCounter((prev: any) => prev - ReadMessage);
            }
          });

          connection.current.on("ReplyMessage", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("ReplyMessage:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            // toast.info("Nowa wiadomość!");

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 0,
                ActionType: 2,

                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            // setAllConversations((prevData: any) => {
            //   const updatedData = prevData.map((item: any) =>
            //     item.Id === Conversation.Id
            //       ? {
            //           ...item,
            //           LastMessage: {
            //             ...item.LastMessage,
            //             Content: Conversation.LastMessage.Content,
            //           },
            //         }
            //       : item,
            //   );

            //   if (updatedData[0].Id !== Conversation.Id) {
            //     updatedData.sort((a: any, b: any) =>
            //       a.Id === Conversation.Id ? -1 : 1,
            //     );
            //   }

            //   return updatedData;
            // });
          });

          connection.current.on("SendVoice", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("SendVoice:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            // toast.info("Nowa wiadomość głosowa!");

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 2,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: [
                  {
                    FileId: Conversation.LastMessage.Id,
                    FilePath: parsedMessage?.VoiceFilePath,
                    FilePathMin: parsedMessage?.VoiceFilePath,
                  },
                ],
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            if (Conversation.Id === activeConversationId) {
              chatServices.readMessageById(
                Conversation.Id,
                Conversation.LastMessage.Id,
              );
            }

            if (decodedToken?.AcademyId !== Profile.Id) {
              setUnreadMessagesCounter((prev: any) => prev + 1);
            }

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });
          });

          connection.current.on("SendFiles", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("SendFiles:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            // toast.info("Nowe zdjęcie!");

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 1,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: [
                  {
                    FileId: Conversation.LastMessage.Files[0].MessageId,
                    FilePath: Conversation.LastMessage.Files[0].FilePath,
                    FilePathMin: Conversation.LastMessage.Files[0].FilePathMin,
                  },
                ],
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            if (Conversation.Id === activeConversationId) {
              chatServices.readMessageById(
                Conversation.Id,
                Conversation.LastMessage.Id,
              );
            }

            if (decodedToken?.AcademyId !== Profile.Id) {
              setUnreadMessagesCounter((prev: any) => prev + 1);
            }

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });
          });

          connection.current.on("RemoveMessage", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("RemoveMessage:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            console.log("PRZEDFILTERM", conversation);

            editMessage(Conversation?.LastMessage?.Id);
          });

          connection.current.on(
            "CreateGroupConversation",
            (newConversation: any) => {
              const parsedMessage = JSON.parse(newConversation);
              console.log("CreateGroupConversation:", parsedMessage);
              const { ConversationType, Id, LastMessage, LogoFilePath, Name } =
                parsedMessage;
              connection.current.invoke("JoinConversation", Id);
              setAllConversations((prev: any) => [
                {
                  Id: Id,
                  Name: parsedMessage.Name,
                  Type: 1,
                  LogoFilePath: parsedMessage.LogoFilePath,
                  LogoFilePathMin: parsedMessage.LogoFilePath,
                  LastMessage: {
                    Id: LastMessage.Id,
                    Content: LastMessage.Content,
                    Sent: LastMessage.Sent,
                    IsDelete: false,
                    Type: LastMessage.Type,
                    Sender: null,
                  },
                },
                ...prev,
              ]);
            },
          );

          connection.current.on(
            "CreatePrivateConversation",
            (newConversation: any) => {
              const parsedMessage = JSON.parse(newConversation);
              console.log("CreateGroupConversation:", parsedMessage);
              const {
                ConversationType,
                Id,
                InterlocutorId,
                InterlocutorImage,
                InterlocutorName,
                LastMessage,
                LogoFilePath,
                Name,
                ProfileId,
                ProfileImage,
                ProfileName,
              } = parsedMessage;
              connection.current.invoke("JoinConversation", Id);
              setAllConversations((prev: any) => [
                {
                  Id: Id,
                  Name: InterlocutorName,
                  Type: 0,
                  LogoFilePath: InterlocutorImage,
                  LogoFilePathMin: InterlocutorImage,
                  LastMessage: {
                    Id: LastMessage.Id,
                    Content: LastMessage.Content,
                    Sent: LastMessage.Sent,
                    IsDelete: false,
                    Type: LastMessage.Type,
                    Sender: null,
                  },
                },
                ...prev,
              ]);
            },
          );

          connection.current.on("DeleteGroupConversation", (message: any) => {
            const parsedMessage = JSON.parse(message);
            const { Id, Name, ConversationType, LogofilePath, LastMessage } =
              parsedMessage?.Conversation;

            console.log("DeleteGroupConversation:", parsedMessage);
            const updatedConversations = allConversations.filter(
              (conversation: any) => conversation.Id !== Id,
            );

            setAllConversations(updatedConversations);
            connection.current.invoke("LeaveConversation", Id);
          });

          connection.current.on("DeletePrivateConversation", (message: any) => {
            const parsedMessage = JSON.parse(message);
            const { Id, Name, ConversationType, LogofilePath, LastMessage } =
              parsedMessage?.Conversation;
            console.log("DeletePrivateConversation:", parsedMessage);
            const updatedConversations = allConversations.filter(
              (conversation: any) => conversation.Id !== Id,
            );

            setAllConversations(updatedConversations);
            connection.current.invoke("LeaveConversation", Id);
          });

          connection.current.on("DeleteProfileConversation", (message: any) => {
            const parsedMessage = JSON.parse(message);
            const token = localStorage.getItem("token");
            const decodedToken: any = token && jwtDecode(token);
            const { Profile, Conversation } = parsedMessage;

            console.log("DeleteProfileConversation:", parsedMessage);
            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 3,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });
            const updatedMembers = conversationMembers.filter(
              (member: any) => member.Id !== parsedMessage?.Profile?.Id,
            );

            setConversationMembers(updatedMembers);

            if (decodedToken?.AcademyId === Profile.Id) {
              connection.current.invoke("LeaveConversation", Conversation.Id);
              const updatedConversations = allConversations.filter(
                (conversation: any) => conversation.Id !== Conversation.Id,
              );

              setAllConversations(updatedConversations);
            }
          });

          connection.current.on("LeaveConversation", (message: any) => {
            const parsedMessage = JSON.parse(message);
            const token = localStorage.getItem("token");
            const decodedToken: any = token && jwtDecode(token);
            const { Conversation, Profile } = parsedMessage;

            const updatedConversations = allConversations.filter(
              (conversation: any) => conversation.Id !== Conversation.Id,
            );

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 3,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });

            setAllConversations(updatedConversations);
            console.log("LeaveConversation:", parsedMessage);
            connection.current.invoke("LeaveConversation", Conversation.Id);
          });

          connection.current.on("InviteNewProfile", (message: any) => {
            const parsedMessage = JSON.parse(message);
            const token = localStorage.getItem("token");
            const decodedToken: any = token && jwtDecode(token);
            const { Conversation } = parsedMessage;
            console.log("InviteNewProfile:", parsedMessage);

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 3,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: null,
              },
              ...prev,
            ]);

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });

            setConversationMembers((prev: any) => [
              ...prev,
              {
                Id: parsedMessage?.NewInterlocutor?.Id,
                Type: parsedMessage?.NewInterlocutor?.Type,
                Name: parsedMessage?.NewInterlocutor?.Name,
                PhotoFilePath: parsedMessage?.NewInterlocutor?.FtpPhotoFilePath,
                PhotoFilePathMin:
                  parsedMessage?.NewInterlocutor?.FtpPhotoFilePathMin,
              },
            ]);

            if (
              decodedToken?.AcademyId === parsedMessage?.NewInterlocutor?.Id
            ) {
              connection.current.invoke("JoinConversation", Conversation.Id);
            }
          });

          connection.current.on("ChangeConversationLogo", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("ChangeConversationLogo:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 3,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                // Sender: {
                //   Id: Profile.Id,
                //   Type: Profile.Type,
                //   Name: Profile.Name,
                //   PhotoFilePath: Profile.FtpPhotoFilePath,
                // },
              },
              ...prev,
            ]);
            setActiveConversationImageUrl(Conversation?.LogoFilePath);
            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LogoFilePath: Conversation?.LogoFilePath,
                      LogoFilePathMin: Conversation?.LogoFilePathMin,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });
          });

          connection.current.on("RenameConversation", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("RenameConversation:", parsedMessage);
            const { Conversation, Profile, User } = parsedMessage;
            editConversationName(Conversation?.Id, Conversation?.Name);
            setActiveConversationName(Conversation?.Name);

            setConversation((prev: any) => [
              {
                Id: Conversation.LastMessage.Id,
                Content: Conversation.LastMessage.Content,
                Type: 3,
                ActionType: 0,
                // Type: Conversation.LastMessage.Type,
                // ActionType: Conversation.LastMessage.ActionType,
                Sent: Conversation.LastMessage.Sent,
                Files: Conversation.LastMessage.Files,
                Reply: Conversation.LastMessage.Reply,
                IsDelete: false,

                Sender: {
                  Id: Profile.Id,
                  Type: Profile.Type,
                  Name: Profile.Name,
                  PhotoFilePath: Profile.FtpPhotoFilePath,
                },
              },
              ...prev,
            ]);

            setAllConversations((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === Conversation.Id
                  ? {
                      ...item,
                      LastMessage: {
                        ...item.LastMessage,
                        Content: Conversation.LastMessage.Content,
                        Sent: Conversation.LastMessage.Sent,
                      },
                    }
                  : item,
              );

              if (updatedData[0].Id !== Conversation.Id) {
                updatedData.sort((a: any, b: any) =>
                  a.Id === Conversation.Id ? -1 : 1,
                );
              }

              return updatedData;
            });
          });

          connection.current.on("StatusChanged", (message: any) => {
            const parsedMessage = JSON.parse(message);
            console.log("StatusChanged:", parsedMessage);

            setOnlineProfiles((prevData: any) => {
              const updatedData = prevData.map((item: any) =>
                item.Id === parsedMessage.ProfileId
                  ? {
                      Id: parsedMessage.ProfileId,
                      Type: parsedMessage.ProfileType,
                      IsOnline: parsedMessage.IsOnline,
                    }
                  : item,
              );

              return updatedData;
            });
          });
        })
        .catch((e: any) => console.log("Connection failed: ", e));
    }
  }, [connection, allConversations]);

  return (
    <AllConversationsContext.Provider
      value={[
        allConversations,
        setAllConversations,
        filter,
        setFilter,
        searchQuery,
        setSearchQuery,
        conversation,
        setConversation,
        conversationMembers,
        setConversationMembers,
        activeConversationImageUrl,
        setActiveConversationImageUrl,
        activeConversationName,
        setActiveConversationName,
        onlineProfiles,
        setOnlineProfiles,
        activeConversationId,
        setActiveConversationId,
        activeConversationType,
        setActiveConversationType,
        tempMemberId,
        setTempMemberId,
        unreadMessagesCounter,
        setUnreadMessagesCounter,
        allInterlocutors,
        setAllInterlocutors,
        auxFolderName,
        setAuxFolderName,
        activeMemberType,
        setActiveMemberType,
      ]}
    >
      {children}
    </AllConversationsContext.Provider>
  );
};
export default AllConversationsProvider;
