import { useState, useEffect, useContext } from "react";
import ContentContainer from "components/layout/ContentContainer";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import PlayersTable from "./PlayersTable";
import PlayersTopbar from "./PlayersTopbar";
import { DefaultSortContext } from "contexts/defaultSortContext";
import { IAcademyPlayer } from "types/transfer/player.interface";
import playerService from "services/transfer/playerServices";
import { DataViewMode, ISelectOption, LIST_SORT } from "types/globalTypes";
import { useDebounce } from "use-debounce";
import { enumToSelectOptions } from "utils/baseUtils";
import { getAllSentTransfers } from "services/transferServices";
import { getFolderDetail } from "services/folderServices";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const sorts = enumToSelectOptions(LIST_SORT);

const Players: React.FC = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);


  const [sortBy, setSortBy] = useState<ISelectOption<LIST_SORT> | null>(null);
  const [queryString, setQueryString] = useState("");

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const {
    items: players,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IAcademyPlayer>(
    playerService.getAcademy,
    debounceSearchInput,
  );

  const {
    items: transfers,
    loading: transfersLoading,
    containerRef: transfersContainerRef,
    lastItemRef: transfersLastItemRef,
    refresh: transfersRefresh,
  } = useInfiniteScroll<any>(getAllSentTransfers, debounceSearchInput);

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  const getUniqueGroupName = Array.from(
    new Set(transfers.map((item: any) => item.GroupName)),
  ).map((elem, idx) => {
    return {
      backend: elem,
      pl: elem,
    };
  });

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isPlayerDetailActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 134);

  return (
    <ContentContainer
      title={"Zawodnicy"}
      TopBar={
        <PlayersTopbar
          refresh={refresh}
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          defaultSort={defaultSort}
          handleQueryChange={handleQueryChange}
          getUniqueGroupName={getUniqueGroupName}
        />
      }
    >
      <div
        onClick={() => {
          if (!isPlayerDetailActive) {
            toast.error("Nie masz dostępu. Skontaktuj się z Administratorem.")
          }
        }}
      >
        <div style={{
          pointerEvents: isPlayerDetailActive ? 'auto' : 'none'
        }}>
          <PlayersTable
            data={transfers}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
            isDataLoading={loading}
            sortBy={sortBy}
          />
        </div>
      </div>
      {/* <PlayersTable
        data={players}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
        isDataLoading={loading}
        sortBy={sortBy}
      /> */}

      {/* {viewType === 'table' ? (
        <PlayersTable
          players={players}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
          sortBy={sortBy}
        />
      ) : (
        <PlayersList
          sortBy={sortBy}
          players={players}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      )} */}
    </ContentContainer>
  );
};

export default Players;
