import { conn } from 'api/BaseConnection';
import { IPlayerEvent } from 'types/events/event.interface';
import { IListPageRequest, IListPageResponse } from 'types/globalTypes';
import {
  IAcademyPlayer,
  IAvaliablePlayer,
  IPlayerClubHistory,
  IPlayerDetail,
} from 'types/transfer/player.interface';

const endpoint = conn.endpoints.player;

const getAcademy = async (listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<IAcademyPlayer>>(
    endpoint.get.academy,
    'json',
    { ...listPage }
  );
};

const getAvailable = async (listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<IAvaliablePlayer>>(
    endpoint.get.available,
    'json',
    { ...listPage }
  );
};

const getDetail = async (PlayerId: string) => {
  return await conn.getJSON<IPlayerDetail>(endpoint.get.detail, 'json', {
    PlayerId,
  });
};

const getBasicInformation = async (PlayerId: string) => {
  return await conn.getJSON<IPlayerDetail>(endpoint.get.basicInformation, 'json', {
    PlayerId,
  });
};

const getEvents = async (PlayerId: string, listPage?: IListPageRequest) => {
  return await conn.getJSON<IListPageResponse<IPlayerEvent>>(
    endpoint.get.events,
    'json',
    {
      PlayerId,
      ...listPage,
    }
  );
};

const getClubHistories = async (
  PlayerId: string,
  PlayerCardId: string
): Promise<IPlayerClubHistory[]> => {
  return await conn.getJSON<IPlayerClubHistory[]>(
    endpoint.get.clubHistories,
    'json',
    { PlayerId, PlayerCardId }
  );
};

const playerService = {
  getAcademy,
  getAvailable,
  getDetail,
  getEvents,
  getClubHistories,
  getBasicInformation
};

export default playerService;
