import TabContent from 'components/common/tabs/TabContent';
import React, { useEffect, useState } from 'react';
import Merchant from './Merchant';
import MerchantRegistry from './Registry/MerchantRegistry';
import { ISellerDetail } from 'types/seller.interface';
import sellerServices from 'services/sellerServices';
import getBasicSeller from 'services/sellerServices';

interface Props {
  isSellerActivated?: boolean;
  isAdmin?: boolean;
  isSellerActive?: boolean;
}

const AcademyMerchant: React.FC<Props> = ({ isSellerActivated, isAdmin, isSellerActive }) => {
  const [isMerchantRegistrationDisplayed, setMerchantRegistrationDisplay] =
    useState(false);

  const [seller, setSeller] = useState<any>();

  //ISellerDetail

  const getSellerDetail = async () => {
    try {
      if (isSellerActive && !isAdmin) {
        const response = await sellerServices.getBasicSeller();
        setSeller(response);
      }

      if (isAdmin) {
        const response = await sellerServices.getSeller();
        setSeller(response);
      }
    } catch (error) {
      console.log('Failed to fetch seller details:', error);
    }
  };

  useEffect(() => {
    getSellerDetail();
  }, [isSellerActivated,isAdmin, isSellerActive]);

  return (
    <TabContent id='merchant'>
      <div className='flex flex-col w-full gap-12'>
        {!isMerchantRegistrationDisplayed ? (
          <Merchant
          seller={seller} setMerchantRegistrationDisplay={setMerchantRegistrationDisplay}
          isSellerActivated={isSellerActivated}
          />
        ) : (
          <MerchantRegistry
          seller={seller} setMerchantRegistrationDisplay={setMerchantRegistrationDisplay}
          />
        )}
      </div>
    </TabContent>
  );
};

export default AcademyMerchant;
