import { IDocument } from 'types/fileType';
import { ICombinationValue } from './combination.interface';
import { Country } from 'utils/constants/countires';
import { IAddress } from 'types/address.interface';

export interface IOrderDetailStatstics {
  OrderId: string;
  Created: Date;
  Number: string;
}

export interface IOrderBuyer {
  BuyerId: string;
  Name: string;
}

export interface IOrderSeller {
  SellerId: string;
  Name: string;
}

export interface IOrderDelivery {
  DeliveryId: string;
  Name: string;
}

export interface IOrder {
  OrderId: string;
  Number: string;
  Created: Date;
  Amount: number;
  State: ORDER_HISTORY_STATE;
  Buyer: IOrderBuyer;
  Seller: IOrderSeller;
  Delivery: IOrderDelivery;
  CurrentPaymentStatus: PAYMENT_STATUS;
  detailPassPermission?: boolean | undefined;
}

export interface IOrderDetailItem {
  ItemId: string;
  Name: string;
  EAN: string;
  Quantity: number;
  Tax: number;
  Netto: number;
  Gross: number;
  AttributeName: string;
  Values: ICombinationValue[];
  BannerPhotoFilePath: string;
}

export interface IOrderDetailDelivery extends IOrderDelivery, IAddress {
  Gross: number;
  FirstName: string;
  LastName: string;
}

export interface IOrderDetailHistory {
  HistoryId: string;
  Updated: Date;
  State: ORDER_HISTORY_STATE;
}

export interface IOrderDetailRefound {
  RefundId: string;
  Quantity: number;
  Amount: number;
  Created: Date;
  Description: string;
  RefundStatus: ORDER_REFOUND_STATUS;
  Item: IOrderDetailItem;
}

export interface IInvoice {
  CompanyName: string;
  City: string;
  Street: string;
  BuildingNumber: string;
  FlatNumber: string;
  NIP: string;
  Country: Country;
  PostCode: string;
}

export interface IOrderDocument extends IDocument {
  IsDeletable: boolean;
}

export interface IOrderDetail {
  OrderId: string;
  Number: string;
  Created: Date;
  Amount: number;
  CurrentState: ORDER_HISTORY_STATE;
  Description: string;
  Buyer: IOrderBuyer;
  Seller: IOrderSeller;
  Items: IOrderDetailItem[];
  Delivery: IOrderDetailDelivery;
  Documents: IOrderDocument[];
  Histories: IOrderDetailHistory[];
  Refunds: IOrderDetailRefound[];
  TrackingNumber: string;
  Invoice: IInvoice;
  Comments: string;
  CurrentPaymentStatus: PAYMENT_STATUS;
}

export enum ORDER_HISTORY_STATE {
  'Zapłacone',
  'Oczeuje na zapłatę',
  'Przetwarzane',
  'W dostawie',
  'Zakończone',
}

export enum PAYMENT_STATUS {
  'Oczeuje na zapłatę',
  'Zapłacone',
  'Niepowodzenie',
  'Zweryfikowane',
}

export enum ORDER_SORT_LIST {
  'Najnowsze',
  'Najstarsze',
  'Wartość (rosnąco)',
  'Wartość (malejąco)',
}

export enum ORDER_REFOUND_STATUS {
  'Anulowany',
  'W dostawie',
  'Zatwierdzony',
  'Przetwarzany',
  'Dostawa nie powiodła się',
}
