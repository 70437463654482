import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import ContentContainer from "components/layout/ContentContainer";
import InfoBoxPlaceholder from "components/common/boxes/InfoBox/InfoBoxPlaceholder";
import { IPlayerDetail } from "types/transfer/player.interface";
import PlayerDetailInfo from "./PlayerDetailInfo";
import PlayerDetailTabs from "./tabs/PlayerDetailTabs";
import playerService from "services/transfer/playerServices";
import PlayerDetailTopbar from "./PlayerDetailTopbar";
import { useSelector } from 'react-redux';

interface IPlayerDetailProps {
  isStructure?: boolean;
  playerId?: string;
  closePlayerModalOpen?: any;
}

const PlayersDetail = ({
  isStructure,
  playerId,
  closePlayerModalOpen,
}: IPlayerDetailProps) => {
  const { id } = useParams<{ id: string }>();

  const [player, setPlayer] = useState<IPlayerDetail | null>(null);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAdmin = permission[0]?.Id === -1;
  const isPlayerDetailActive = permission.some((tab: any) => tab.Id === 134);

  const isPlayerDetailStructureActive = permission.some((tab: any) => tab.Id === 205);


  const getPlayerDetail = async () => {
    if (isAdmin) {
      if (isStructure && playerId) {
        const detail = await playerService.getDetail(playerId);
        setPlayer(detail);
        return;
      }
      const detail = await playerService.getDetail(id);
      setPlayer(detail);
    }

    if (!isAdmin && (isPlayerDetailActive || isPlayerDetailStructureActive)) {

      if (isStructure && playerId) {
        const detail = await playerService.getBasicInformation(playerId);
        setPlayer(detail);
        return;
      }
      const detail = await playerService.getBasicInformation(id);
      setPlayer(detail);
    }
  };

  useEffect(() => {
    getPlayerDetail();
  }, []);

  if (!player) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <ContentContainer
      title={player.Name}
      TopBar={
        <PlayerDetailTopbar
          appliedPasses={player.AppliedPasses}
          playerId={playerId}
        />
      }
      noArrow={isStructure ? true : false}
      fn={() => closePlayerModalOpen()}
      path={player.Level === null ? "/outside-players" : "/players"}
    >
      <div>
        <PlayerDetailInfo player={player} />
        <PlayerDetailTabs player={player} refresh={getPlayerDetail} />
      </div>
    </ContentContainer>
  );
};

export default PlayersDetail;
