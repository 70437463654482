import axios, { AxiosInstance, AxiosResponse, ResponseType } from "axios";
import qs from "qs";
import { IServiceResponse } from "types/services.interface";
export type IConnections = typeof connections.development;

export interface IRequestError {
  status: number;
  errors?: Array<Array<string>>;
  Errors?: Array<Array<string>>;
}

class BaseConnection {
  authentication: string;
  auth_token: string;
  base_url: string;
  endpoints: Omit<IConnections, "core">;
  conn: AxiosInstance;
  constructor(connections: IConnections) {
    this.authentication = "";
    this.auth_token = "";

    const { core, ...endpoints } = connections;
    this.base_url = core;
    this.endpoints = endpoints;

    this.conn = axios.create({
      timeout: 100 * 1000,
    });

    this.conn.interceptors.response.use(
      (response) => response,
      (error) => {
        const serverErrorRegex = /^5[0-9]{2}$/;
        const isServerError =
          !error.response || serverErrorRegex.test(error.response.status);
        if (
          error.response.data?.Errors?.Message[0] ===
          "Attempted to perform an unauthorized operation."
        ) {
          return Promise.reject({
            response: {
              data: {
                Errors: ["Nie masz uprawnień do tej akcji!"],
              },
            },
          });
        }
        if (isServerError) {
          return Promise.reject({
            response: {
              data: {
                Errors: [
                  "Przepraszamy, wystąpił błąd przy połączeniu z serwerem. Skontakuj się z ...",
                ],
              },
            },
          });
        }
        if (error.response.status === 403) {
          return Promise.reject({
            response: {
              data: {
                Errors: ["Brak uprawnień do wybranej opcji"],
              },
            },
          });
        }

        return Promise.reject(error);
      },
    );
  }

  setAuthToken(token: string) {
    this.authentication = token;
    if (token && token.indexOf("Bearer ") === 0) {
      this.conn.defaults.headers.common.Authorization = token;
    } else {
      delete this.conn.defaults.headers.common.Authorization;
    }
  }

  getJSON<T>(
    url: string,
    type: ResponseType = "json",
    payload = {},
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .get(this.base_url + url, {
          responseType: type,
          params: payload,
          paramsSerializer: function (params) {
            return qs.stringify(params, { indices: false });
          },
        })
        .then((response: AxiosResponse<IServiceResponse<T>>) => {
          const { StatusCode, Data } = response.data;

          if (StatusCode < 300 && StatusCode >= 200) {
            resolve(Data);
          }
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  postJSON<T>(
    endpoint: string,
    type: ResponseType = "json",
    payload = {},
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .post(this.base_url + endpoint, payload, {
          responseType: type,
        })
        .then((response: AxiosResponse<IServiceResponse<T>>) => {
          const { Data } = response.data;
          resolve(Data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  putJSON(
    url: string,
    type: ResponseType = "json",
    payload = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .put(this.base_url + url, payload, { responseType: type })
        .then((response) => {
          const { data } = response.data;
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  patchJSON(
    url: string,
    type: ResponseType = "json",
    payload = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.conn
        .patch(this.base_url + url, payload, { responseType: type })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  deleteJSON(url: string, type: ResponseType = "json", payload = {}) {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .delete(this.base_url + url, { responseType: type, data: payload })
        .then((response) => {
          const { data } = response.data;
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  protected all(arr: Array<any>) {
    return axios.all(arr);
  }

  // setAuthorizationErrorHandler(errorHandler) {
  //   this.authorizationErrorHandler = errorHandler;
  // }
}

const remoteEndpoints = {
  artists: {
    base: "/artists/api/Artists",
    pressPacks: "/artists/api/Presspacks",
    storedFiles: "/artists/api/StoredFiles",
  },
  applicationUser: "/api/applicationUser",
  auth: "/api/User",
  conditions: {
    base: "/conditions/api/Conditions",
    licensor: "/conditions/api/Conditions/Licensor",
    templates: "/conditions/api/Conditions/Templates",
  },
  digitalReleases: {
    base: "/digitalrelease/api/DigitalReleases",
    artists: "/digitalrelease/api/Artists",
  },
  licensors: "/labels/api/Licensors",
  permissions: "/auth/api/Permissions",
  profiles: "/auth/api/Profiles",
  providers: "/provider/api/providers",
  salesChannel: {
    salesChannel: "/saleschannel/api/SalesChannels",
    groups: "/saleschannel/api/Groups",
    providers: "/saleschannel/api/Providers",
    subGroups: "/saleschannel/api/Subgroups",
  },
  tracks: { base: "/track/api/Tracks", artists: "/track/api/Artists" },
  users: "/auth/api/Users",
  user: "/auth/api/User",
  profile: "/api/profile",
  files: "/api/files",
  filesCategory: "/api/filesCategory",
  permission: "/api/Permission",
  marketpleace: {
    product: {
      getDetail: "/api/Marketplace/GetProductDetail",
      getAll: "/api/Marketplace/GetSellerProducts",
      getFiltered: "/api/Marketplace/GetFilterSellerProducts",
      create: "/api/Marketplace/CreateProduct",
      update: "/api/Marketplace/UpdateProduct",
      delete: "/api/Marketplace/DeleteProduct",
      changeState: "/api/Marketplace/ChangeProductStatus",
      photo: {
        add: "/api/Marketplace/AddProductPhotoFiles",
        delete: "/api/Marketplace/DeleteProductPhotoFile",
      },
      additionalFile: {
        add: "/api/Marketplace/AddAdditionalFile",
        delete: "/api/Marketplace/DeleteAdditionalFile",
      },
      combination: {
        getAttributes: "/api/Marketplace/GetAttributes",
        add: "/api/Marketplace/CreateCombination",
        update: "/api/Marketplace/UpdateCombination",
        remove: "/api/Marketplace/DeleteCombination",
      },
      getStatistics: "/api/Seller/GetProductStatistics",
      discountTemplate: {
        get: "/api/Seller/GetDiscountTemplates",
        save: "/api/Seller/SaveDiscountTemplate",
        delete: "/api/Seller/DeleteDiscountTemplate",
        apply: "/api/Seller/ApplyDiscountTemplate",
        discard: "/api/Marketplace/DeleteDiscount",
      },
    },
    delivery: {
      get: "/api/Seller/GetSellerDeliveries",
      addPhysical: "/api/Seller/CreatePhysicalDelivery",
      addDigital: "/api/Seller/CreateDigitalDelivery",
      update: "/api/Seller/UpdateDelivery",
      delete: "/api/Seller/DeleteDelivery",
    },
    category: {
      get: "/api/Marketplace/GetCategories",
    },
    order: {
      getAll: "/api/Marketplace/GetOrdersBySellerId",
      getDetail: "/api/Marketplace/GetOrderDetail",
      changeState: "/api/Marketplace/ChangeOrderState",
      document: {
        add: "/api/Seller/AddOrderDocument",
        remove: "/api/Seller/DeleteOrderDocument",
      },
    },
  },
  scanner: {
    add: '/api/Scanner',
    update: '/api/Scanner',
    delete: '/api/Scanner',
    activate: '/api/Scanner/Activate',
    deactivate: '/api/Scanner/Activate',
    get: {
      detail: '/api/Scanner/Detail',
      listing: '/api/Scanner/Listining'
    }
  },
  adacemy: {
    get: "/api/Academy",
    basicAcademy: "/api/Academy/BasicInformation",
    detailAcademyData: '/api/Academy/Data',
    getUsers: "/api/Academy/Users",
    getUsersContact: "/api/Academy/UsersContact",
    changeVisibility: "/api/Academy/ChangeVisibility",
    update: {
      representingPerson: "/api/Academy/UpdateRepresentingPerson",
      address: "/api/Academy/UpdateAddress",
      merchant: "/api/Academy/UpdateMerchant",
      contact: "/api/Academy/UpdateContact",
      primaryData: "/api/Academy/UpdatePrimaryData",
      union: "/api/Academy/UpdateUnion",
      accessState: "/api/Academy/ChangeAccessStatus",
      photoFilePath: "/api/Academy/UpdatePhotoFile",
    },
  },
  pass: {
    get: {
      detail: "/api/Transfer/PassDetail",
      all: "/api/Transfer/AcademyPasses",
      transactions: "/api/Transfer/PassTransactions",
      playerHistory: "/api/Transfer/PlayerHistoryPasses",
    },
    create: {
      free: "/api/Transfer/CreateFreePass",
      paid: "/api/Transfer/CreatePaidPass",
    },
    setForFree: "/api/Transfer/SetPassForFree",
    changePrice: "/api/Transfer/ChangePassPrice",
    revoke: "/api/Transfer/RevokePass",
    apply: "/api/Transfer/ApplyPass",
    remove: "/api/Transfer/DeletePass",
  },
  player: {
    get: {
      detail: "/api/Transfer/PlayerDetail",
      basicInformation:"/api/Transfer/PlayerBasicInformation",
      academy: "/api/Transfer/AcademyPlayers",
      available: "/api/Transfer/AvailablePlayers",
      events: "/api/Event/PlayerEventAttendance",
      clubHistories: "/api/Transfer/PlayerClubHistories",
    },
  },
  trainers: {
    avilable: "/api/Transfer/AvailableTrainers",
    academy: "/api/Transfer/AcademyTrainers",
  },
  transfer: {
    get: "/api/Transfer/SentTransfers",
    getAcademyMembers: "/api/Transfer/AcademyPlayersAndTrainersCount",
    send: "/api/Transfer/SendTransfer",
    accept: "/api/Transfer/AcceptTransfer",
    exclude: "/api/Transfer/ExcludePlayer",
    include: "/api/Transfer/IncludePlayer",
    revoke: "/api/Transfer/RevokeTransfer",
    cancel: "/api/Transfer/CancelRequest",
    trainerForPlayer: {
      remove: "/api/Transfer/RemoveTrainerForPlayer",
      designate: "/api/Transfer/DesignateTrainerToPlayer",
    },
    trainerForPlayers: {
      remove: "/api/Transfer/RemoveTrainerForPlayers",
      designate: "/api/Transfer/DesignateTrainerToPlayers",
      getDesignate: "/api/Transfer/GetDesignatePlayersForTrainer",
    },
  },
  delivery: "/api/Delivery",
  playerProfile: "/api/PlayerProfile",
  trainerProfile: "/api/TrainerProfile",
  order: "/api/Order",
  notification: "/api/Notification",
  chat: "/api/Chat",
  event: "/api/Event",
  cyclicalEvent: "/api/CyclicalEvent",
  favoriteLocation: "/api/FavoriteLocation",
  seller: {
    get: "/api/Seller/GetSellerDetail",
    getSellerDeliveries: "/api/Seller/GetSellerDeliveries",
    getSellerBasicInfo: "/api/Academy/SellerInformation",
  },
  system: {
    uploadFile: "/api/System/UploadFile",
  },
};

const connections = {
  development: {
    core: "https://api.academiq.pl",
    // core: "https://adminapi.justwin.pl",
    ...remoteEndpoints,
  },
  production: {
    core: "https://api.academiq.pl",
    // core: "https://adminapi.justwin.pl",
    ...remoteEndpoints,
  },
  test: {
    core: "https://localhost",
    artists: {
      base: ":5002/api/Artists",
      pressPacks: ":5002/api/Presspacks",
      storedFiles: ":5002/api/StoredFiles",
    },
    applicationUser: ":5002/api/applicationUser",
    auth: ":5001/api/Auth",
    conditions: {
      base: ":5003/api/Conditions",
      licensor: ":5003/api/Conditions/Licensor",
      templates: ":5003/api/Conditions/Templates",
    },
    digitalReleases: {
      base: ":50010/api/DigitalReleases",
      artists: ":50010/api/Artists",
    },
    licensors: ":50011/api/Licensors",
    permissions: ":5001/api/Permissions",
    profiles: ":5001/api/Profiles",
    providers: ":5001/api/Providers",
    salesChannel: {
      groups: ":50021/api/Groups",
      salesChannel: ":50021/api/SalesChannels",
      subGroups: ":50021/api/Subgroups",
      providers: ":50021/api/Providers",
    },
    tracks: { base: ":50011/api/Tracks", artists: ":50011/api/Artists" },
    users: ":5002/api/Users",
    user: ":5002/api/User",
    profile: ":5004/profile",
    files: ":5002/api/files",
    filesCategory: ":5002/api/filesCategory",
    permission: ":5002/api/Permission",
    marketpleace: {
      product: {
        getDetail: ":5002/api/Marketplace/GetProductDetail",
        getAll: ":5002/api/Marketplace/GetSellerProducts",
        getFiltered: ":5002/api/Marketplace/GetFilterSellerProducts",
        create: ":5002/api/Marketplace/CreateProduct",
        update: ":5002/api/Marketplace/UpdateProduct",
        delete: ":5002/api/Marketplace/DeleteProduct",
        changeState: "/api/Marketplace/ChangeProductStatus",
        photo: {
          add: ":5002/api/Marketplace/AddProductPhotoFiles",
          delete: ":5002/api/Marketplace/DeleteProductPhotoFile",
        },
        additionalFile: {
          add: ":5002/api/Marketplace/AddAdditionalFile",
          delete: ":5002/api/Marketplace/DeleteAdditionalFile",
        },
        combination: {
          getAttributes: ":5002/api/Marketplace/GetAttributes",
          add: ":5002/api/Marketplace/CreateCombination",
          update: ":5002/api/Marketplace/UpdateCombination",
          remove: ":5002/api/Marketplace/DeleteCombination",
        },
        getStatistics: ":5002/api/Seller/GetProductStatistics",
        discountTemplate: {
          get: ":5002/api/Seller/GetDiscountTemplates",
          save: ":5002/api/Seller/SaveDiscountTemplate",
          delete: ":5002/api/Seller/DeleteDiscountTemplate",
          apply: ":5002/api/Seller/ApplyDiscountTemplate",
          discard: ":5002/api/Marketplace/DeleteDiscount",
        },
      },
      delivery: {
        get: ":5002/api/Seller/GetSellerDeliveries",
        addPhysical: ":5002/api/Seller/CreatePhysicalDelivery",
        addDigital: ":5002/api/Seller/CreateDigitalDelivery",
        update: ":5002/api/Seller/UpdateDelivery",
        delete: ":5002/api/Seller/DeleteDelivery",
      },
      category: {
        get: ":5002/api/marketplace/getCategories",
      },
      order: {
        getAll: ":5002/api/Marketplace/GetOrdersBySellerId",
        getDetail: ":5002/api/Marketplace/GetOrderDetail",
        changeState: ":5002/api/Marketplace/ChangeOrderState",
        document: {
          add: ":5002/api/Seller/AddOrderDocument",
          remove: ":5002/api/Seller/DeleteOrderDocument",
        },
      },
    },
    pass: {
      get: {
        detail: "/api/Transfer/PassDetail",
        all: "/api/Transfer/AcademyPasses",
        transactions: "/api/Transfer/PassTransactions",
        playerHistory: "/api/Transfer/PlayerHistoryPasses",
      },
      create: {
        free: "/api/Transfer/CreateFreePass",
        paid: "/api/Transfer/CreatePaidPass",
      },
      setForFree: "/api/Transfer/SetPassForFree",
      changePrice: "/api/Transfer/ChangePassPrice",
      revoke: "/api/Transfer/RevokePass",
      apply: "/api/Transfer/ApplyPass",
      remove: "/api/Transfer/DeletePass",
    },
    player: {
      get: {
        detail: "/api/Transfer/PlayerDetail",
        basicInformation:"/api/Transfer/PlayerBasicInformation",
        academy: "/api/Transfer/AcademyPlayers",
        available: "/api/Transfer/AvailablePlayers",
        events: "/api/Event/PlayerEventAttendance",
        clubHistories: "/api/Transfer/PlayerClubHistories",
      },
    },
    trainers: {
      avilable: "/api/Transfer/AvailableTrainers",
      academy: "/api/Transfer/AcademyTrainers",
    },
    transfer: {
      get: "/api/Transfer/SentTransfers",
      getAcademyMembers: "/api/Transfer/AcademyPlayersAndTrainersCount",
      send: "/api/Transfer/SendTransfer",
      accept: "/api/Transfer/AcceptTransfer",
      exclude: "/api/Transfer/ExcludePlayer",
      include: "/api/Transfer/IncludePlayer",
      revoke: "/api/Transfer/RevokeTransfer",
      cancel: "/api/Transfer/CancelRequest",
      trainerForPlayer: {
        remove: "/api/Transfer/RemoveTrainerForPlayer",
        designate: "/api/Transfer/DesignateTrainerToPlayer",
      },
      trainerForPlayers: {
        remove: "/api/Transfer/RemoveTrainerForPlayers",
        designate: "/api/Transfer/DesignateTrainerToPlayers",
        getDesignate: "/api/Transfer/GetDesignatePlayersForTrainer",
      },
    },
    scanner: {
      add: '/api/Scanner',
      update: '/api/Scanner',
      delete: '/api/Scanner',
      activate: '/api/Scanner/Activate',
      deactivate: '/api/Scanner/Activate',
      get: {
        detail: '/api/Scanner/Detail',
        listing: '/api/Scanner/Listining'
      }
    },
    adacemy: {
      get: ":5002/api/Academy",
      basicAcademy: ':5002/academy/basicAcademy',
      detailAcademyData: ':5002/api/Academy/Data',
      getUsers: ":5002/api/Academy/Users",
      getUsersContact: ":5002/api/Academy/UsersContact",
      changeVisibility: ":5002/api/Academy/ChangeVisibility",
      update: {
        representingPerson: "/api/Academy/UpdateRepresentingPerson",
        address: "/api/Academy/UpdateAddress",
        merchant: "/api/Academy/UpdateMerchant",
        contact: "/api/Academy/UpdateContact",
        primaryData: "/api/Academy/UpdatePrimaryData",
        union: "/api/Academy/UpdateUnion",
        accessState: "/api/Academy/ChangeAccessStatus",
        photoFilePath: "/api/Academy/UpdatePhotoFile",
      },
    },
    delivery: ":5002/api/Delivery",
    playerProfile: ":5002/api/PlayerProfile",
    trainerProfile: ":5002/api/TrainerProfile",
    order: ":5002/api/Order",
    notification: ":5002/api/Notification",
    chat: ":5002/api/Chat",
    event: ":5002/api/Event",
    cyclicalEvent: ":5002/api/CyclicalEvent",
    favoriteLocation: ":5002/api/FavoriteLocation",
    seller: {
      get: ":5002/api/Seller/GetSellerDetail",
      getSellerDeliveries: ":5002/api/Seller/GetSellerDeliveries",
      getSellerBasicInfo: ":5002/api/Academy/SellerInformation",
    },
    system: {
      uploadFile: ":5002/api/System/UploadFile",
    },
  },
};

export const conn = new BaseConnection(connections[process.env.NODE_ENV]);

export default BaseConnection;
